<template>
    <div class="component header" :class="{'lobby': lobby}">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <div class="container-fluid container-fluid-header">
                <a class="navbar-brand">
                    <img data-aos="fade-in" v-if="logo && $route.path != '/platform/main-lobby'" :src="logo" alt="">
                </a>
                <div class=" navbar-collapse" id="navbarSupportedContent">
                    <div class="form-inline my-2 my-lg-0 mr-auto">
                        <router-link to="/platform/menu">
                            <img src="@/assets/images/menu.png" alt="" class="menu-orange">
                            <img src="@/assets/images/menu_yellow.svg" alt="" class="menu-yellow">
                        </router-link>
                        <!-- <router-link to="/platform/main-lobby" style="text-decoration: none;">
                            <div class="lobby-icon">
                                LOBBY
                            </div>
                        </router-link> -->
                        <div class="icon" v-if="contact.whatsapp" style="width: 30px; height: 24px;">
                            <a :href="contact.whatsapp" target="_blank" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style="width: 32px; height: 32px; margin-top: -3px; margin-left: 35px;" >
                                    <path fill="#d1a391" d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01A9.82 9.82 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.23 8.23 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23c-1.48 0-2.93-.39-4.19-1.15l-.3-.17l-3.12.82l.83-3.04l-.2-.32a8.2 8.2 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31c-.22.25-.87.86-.87 2.07c0 1.22.89 2.39 1 2.56c.14.17 1.76 2.67 4.25 3.73c.59.27 1.05.42 1.41.53c.59.19 1.13.16 1.56.1c.48-.07 1.46-.6 1.67-1.18s.21-1.07.15-1.18c-.07-.1-.23-.16-.48-.27c-.25-.14-1.47-.74-1.69-.82c-.23-.08-.37-.12-.56.12c-.16.25-.64.81-.78.97c-.15.17-.29.19-.53.07c-.26-.13-1.06-.39-2-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.12-.24-.01-.39.11-.5c.11-.11.27-.29.37-.44c.13-.14.17-.25.25-.41c.08-.17.04-.31-.02-.43c-.06-.11-.56-1.35-.77-1.84c-.2-.48-.4-.42-.56-.43c-.14 0-.3-.01-.47-.01" /></svg>
                            </a>
                        </div>
                    </div>
                    <ul class="navbar-nav ">
                        <div class="balances" v-if="balances">
                            <div class="balance" v-for="summary in balances" v-if="summary.moneyType.shortName != 'PM'">
                                <div class="value">
                                    <div class="code">
                                        {{ summary.currency.code }}
                                    </div>
                                    <div class="money">
                                        {{ summary.value | moneyFormat(true) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </nav>
        <nav v-if="getPair" style="background-color: red;">
            <div class="text-center">
                Streaming on device {{ getPair.device_id }}
            </div>
        </nav>
        <div class="overlays">
            <div class="sessions-overlay">
                <div class="sessions">
                    <div @click="resumeSession(session)" class="session" v-for="session in activeSessions" data-aos="fade-left">
                        You have an active session on <b>Machine #{{ session.machine.number }}</b>
                        <div class="resume"><b>Click to resume session</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Buffer } from 'buffer';
import { mapGetters, mapActions } from 'vuex';
export default {
    computed: mapGetters(['loggedIn', 'getPair']),
    data() {
        return {
            user: null,
            balances: null,
            interval: null,
            activeSessions: null,
            logo: null,
            logoMenu: null,
            lobby: false,
            contact: {
                whatsapp: null,
            }
        }
    },
    watch: {
        '$route': {
            handler() {
                this.lobby = (this.$route.path.includes('/manufacturers'));
            }
        }
    },
    async mounted() {

        if(process.env.VUE_APP_CONTACT_WHATSAPP) {
            this.contact.whatsapp = process.env.VUE_APP_CONTACT_WHATSAPP;
        }

        if(process.env.VUE_APP_CONTACT_TELEGRAM) {
            this.contact.telegram = process.env.VUE_APP_CONTACT_TELEGRAM;
        }


        if (this.$route.path.includes('/manufacturers')) {
            this.lobby = true;
        }
        this.getLogo();
        this.getLogoMenu();
        await this.getUser();
        await this.getBalance();

        setTimeout(async () => {
            await this.getActiveSessions();
        }, 2000);

        var self = this;
        self.interval = setInterval(function() {
            self.getBalance();
            self.getActiveSessions();
        }, 5000);

        // Init cast
        window['__onGCastApiAvailable'] = function(isAvailable) {
            console.log('isav', isAvailable);
            if (isAvailable) {
                initializeCastApi();
            }
        };

    },
    destroyed() {
        clearInterval(this.interval);
    },
    sockets: {
        refreshBalance: function() {
            this.getBalance();
        },
    },
    methods: {
        async getLogo() {
            try {
                let response = await this.$http({
                    method: 'get',
                    url: '/unigens/download_picture_by_key?path=CASINO_PLAYER&key=SYS_LOBBY_LOGO',
                    responseType: 'arraybuffer',
                });

                let mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                this.logo = 'data:' + mimeType + ';base64,' + imgBase64;
            } catch (error) {
                console.log(error);
            }
        },
        async getLogoMenu() {
            try {
                let response = await this.$http({
                    method: 'get',
                    url: '/unigens/download_picture_by_key?path=CASINO_PLAYER&key=SYS_LOGIN_LOGO',
                    responseType: 'arraybuffer',
                });

                let mimeType = response.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                this.logo = 'data:' + mimeType + ';base64,' + imgBase64;
            } catch (error) {
                console.log(error);
            }
        },
        async resumeSession(session) {
            this.$router.push('/platform/layout/' + session.distributed_id + '?device=Desktop&designStyle=GrayMetal');
        },
        async getActiveSessions() {
            try {
                let response = await this.$http.post(process.env.VUE_APP_API_SIGNAL + '/api/activeSessions', {
                    player_id: this.user.playerId,
                });
                this.activeSessions = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        async getUser() {
            try {
                let response = await this.$http.get('application/userDetails');
                this.user = response.data;
            } catch (error) {
                console.log(error);

                if (error.response && error.response.status == 401) {
                    this.logout();
                }

                //this.$toasted.error('Server error');
            }
        },
        async getBalance() {
            try {
                let response = await this.$http.get('players/details');
                this.balances = response.data.account.summaries;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        ...mapActions(['logout']),
        clickLogout() {
            console.log('logout');
            this.logout();
        },
    }
}
</script>