<template>
    <div class="view manufacturers" data-aos="fade-in">
        <div class="container-fluid">
            <!-- Title -->
            <div class="title">
                <router-link class="no-link" to="/platform/">
                    <div class="back"><i class="bi bi-chevron-left"></i>
                        Lobby</div>
                    </router-link>
                <div class="name">
                    LIVE<br><b>SLOTS</b>
                </div>
            </div>
            <!-- Manufacturers Simple -->
            <div class="row manufacturers  justify-content-center" v-if="!extended">
                <div class="col-xxl-4 col-xl-3 col-lg-4 col-sm-6 col-12" v-for="item in data" :key="item.id" >
                    <router-link class="no-link" :to="'/platform/manufacturers/' + item.GroupId + '?type=' + item.GroupType">
                        <div class="manufacturer" :style="[{ border: '3px solid' + item.Color }]">
                            <div class="top">
                                <div class="name" style="font-size: 20px;">
                                    {{ item.GroupName }}
                                </div>
                                <div class="available-icon">Available</div>
                            </div>
                            <div class="overlay">
                                {{ item.TotalAvailableMachines }}<br>
                            </div>
                            <div class="small">Total Slots {{ item.TotalMachines }}</div>
                            <div class="total" :style="[{ backgroundColor: item.Color + '80', borderTop: '3px solid' + item.Color}]">
                                <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                                <div class="text">
                                    <div class="left">
                                        <b>Min Bet</b><br>
                                        ₺ {{ item.MinBet | moneyFormat }}
                                    </div>
                                    <div class="right">
                                        <b>Max Bet</b><br>
                                        ₺ {{ item.MaxBet | moneyFormat }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- Manufacturers Extended -->
            <div class="row manufacturers extended justify-content-center" v-if="extended">
                <div class="col-xxl-4 col-xl-3 col-lg-4 col-sm-6 col-12" v-for="item in data" :key="item.id" >
                    <router-link class="no-link" :to="'/platform/manufacturers/' + item.GroupId + '?type=' + item.GroupType">
                        <div class="manufacturer" :style="[{ border: '3px solid' + item.Color }]">

                            <div class="background" v-if="item.BackgroundImage">
                                <auth-img  :filename="item.BackgroundImage.fileName" :src="getSlotImage(item.BackgroundImage.fileName)" alt="" style="width: 100%; height: 100%;" /> 
                            </div>
                            <div class="gradient" v-if="item.Color" :style="`background: transparent linear-gradient(180deg, #FFFFFF00 0%, ${item.Color} 100%) 0% 0% no-repeat padding-box;`"></div>
                            <div class="available-icon">Available</div>
                            <div class="top">
                                <div class="manufacturer-logo" v-if="item.ManufacturerImage">
                                    <auth-img  :filename="item.ManufacturerImage.fileName" :src="getSlotImage(item.ManufacturerImage.fileName)" alt="" style="width: 100%; height: 50px;" />
                                </div>
                                <div class="group-logo" v-if="item.Image">
                                    <auth-img  :filename="item.Image.fileName" :src="getSlotImage(item.Image.fileName)" alt="" style="width: 100%; height: 100px;" />
                                </div>
                                <div class="name" v-if="!item.Image">
                                    {{ item.GroupName }}
                                </div>
                                
                            </div>

                            <div class="overlay">
                                {{ item.TotalAvailableMachines }}<br>
                            </div>
                            <div class="small">Total Slots {{ item.TotalMachines }}</div>
                            <div class="total" >
                                <div class="text">
                                    <div class="left">
                                        <b>Min Bet</b><br>
                                        ₺ {{ item.MinBet | moneyFormat }}
                                    </div>
                                    <div class="right">
                                        <b>Max Bet</b><br>
                                        ₺ {{ item.MaxBet | moneyFormat }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import AuthImg from '@/components/AuthImg/AuthImg.vue';
export default {
    name: "ManufacturersView",
    data() {
        return {
            data: null,
            sideSessions: null,
            user: null,
            showSideSessions: false,
            liveGames: null,
            extended: false,
        }
    },
    components: {
        AuthImg,
    },
    computed: {
        lobbyEnabled() {
            return process.env.VUE_APP_MAIN_LOBBY_ENABLED;
        }
    },
    async mounted() {

        if(process.env.VUE_APP_SLOT_GROUPS_EXTENDED == "true") {
            this.extended = true;
        }

        await this.getUser();


        this.getManufactures();
        this.getSideSessions();

        if (process.env.VUE_APP_LOBBY_ENABLED == 'true') {
            this.getLiveGames();
        }

        let specialUsers = ['savoy_demo_1', 'savoy_demo_2', 'casino_demo'];
        if (specialUsers.includes(this.user.userName)) {
            this.showSideSessions = false;
        }


    },
    sockets: {
        update_broadcasters: function() {
            this.getManufactures();
        },
    },
    methods: {
        getSlotImage(filename) {
            return process.env.VUE_APP_API_LOCALSERVER + '/api/v1/unigens/download_picture?fileName=' + filename
        },
        async createLiveGameSession(game) {
            try {
                let response = await axios.post('religa/sessions', game);
                let session = response.data;
                this.$router.push(`/live-games?userKey=${session.userKey}&tableId=${game.gameId}&gameType=${game.gameType}`)
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
            //:to="'/live-games?gameId=' + item.gameId" 
        },
        async getLiveGames() {
            try {
                let response = await this.$http.get('religa/games');
                this.liveGames = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        async getUser() {
            try {
                let response = await this.$http.get('application/userDetails');
                this.user = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        async createSideSession(mainSession) {
            console.log('creating', mainSession);
            // Get current user
            let response = await this.$http.get('application/userDetails');
            let user = response.data;
            // Create the session and redirect
            let response2 = await this.$http.get(`unigens/create_player_side_session?sideSessionGuid=${mainSession.Guid}&playerId=${user.playerId}&sideGamePercent=0&playerSocketId=${this.$socket.id}`);
            if (response2.data.Status == "OK") {
                let sideSessionId = response2.data.Result[0].Guid;
                this.$router.push('/platform/layout/' + sideSessionId + '?device=Desktop&system=SidePlayer');
            } else {
                console.log('api error', response2.data);
                this.$toasted.error(response2.data.Result.ErrorCode);
            }
        },
        async getSideSessions() {
            let response = await this.$http.get('unigens/get_side_sessions?filterStatus=LIVE');
            this.sideSessions = response.data.Result;
        },
        async getManufactures() {
            let response = await this.$http.get('unigens/get_machine_groups');
            this.data = response.data.Result;
        },
    }
}
</script>