<template>
    <div class="view app">
        <div class="live-games">
            <iframe 
            class="religa-iframe"
            allow="autoplay; fullscreen"    
            :src="`https://center.reallivegame.com/?userKey=${$route.query.userKey}&lang=en&tableId=${$route.query.tableId}&gameType=${$route.query.gameType}&public=1i99bonvpl6s64ihd8sca&hasLobby=false&homeUrl=https://724livecasino.com`" width="100vw" height="100vh" align="left"></iframe>
        </div>
    </div>
</template>
<style lang="scss">
.view.app {
    .religa-iframe {
        border: none;
        width: 100vw;
        height: 100svh;
    }
}
</style>
<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
export default {
    name: "LiveGamesView",
    data() {
        return {
        }
    },
    async mounted() {
        window.alg.redirect = (homeUrl) => {
            this.$router.push('/');
        }
    },
    methods: {
    }
}
</script>